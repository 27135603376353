<template>
  <v-app>
    <!-- Toolbar -->
    <v-app-bar app dense dark color="primary" elevation="0">
      <v-app-bar-nav-icon
        @click.stop="leftNavigationDrawer = !leftNavigationDrawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>moped3d.de</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom :disabled="$vuetify.breakpoint.mobile ? true : false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="clickFahrzeugAuswaehlen">
            <v-icon> mdi-motorbike </v-icon>
          </v-btn>
        </template>
        <span>Moped auswählen</span>
      </v-tooltip>
      <v-tooltip bottom :disabled="$vuetify.breakpoint.mobile ? true : false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            @click="clickBaugruppenAuswaehlen"
          >
            <v-icon> mdi-file-tree </v-icon>
          </v-btn>
        </template>
        <span>Baugruppe auswählen</span>
      </v-tooltip>
      <!--<v-menu open-on-hover bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon> mdi-dots-vertical </v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(item, i) in menuitemsweiterefunk"
            :key="i"
            @click="menuitemsweiterefunkchanged(i)"
            link
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>-->
    </v-app-bar>

    <!-- Navigation Drawer -->
    <v-navigation-drawer app v-model="leftNavigationDrawer" touchless>
      <v-list nav dense>
        <v-list-item @click="clickFahrzeugAuswaehlen">
          <v-list-item-icon>
            <v-icon>mdi-motorbike</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Moped auswählen</v-list-item-title>
        </v-list-item>
        <v-list-item @click="clickBaugruppenAuswaehlen">
          <v-list-item-icon>
            <v-icon>mdi-file-tree</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Baugruppe auswählen</v-list-item-title>
        </v-list-item>
        <!--<v-list-item @click="clickEinstellungenAuswaehlen">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Einstellungen</v-list-item-title>
        </v-list-item>-->
      </v-list>
      <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Statistik</v-list-item-title>
                        <v-list-item-subtitle>
              Fahrzeuge: 2
            </v-list-item-subtitle>
                        <v-list-item-subtitle>
              Ersatzteile: 3096
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
    </v-navigation-drawer>

    <!--Fahrzeug-Navigation-Drawer-Rechts-->
    <v-navigation-drawer
      app
      v-model="fahrzeugdrawer"
      touchless
      :mobile-breakpoint="0"
      right
      :width="$vuetify.breakpoint.xs ? '100%' : '25%'"
    >
      <v-card flat height="100%">
        <v-card-title class="pa-0">
          <v-toolbar dark dense color="primary" elevation="0">
            <v-btn icon dark @click="fahrzeugdrawer = !fahrzeugdrawer">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Fahrzeug auswählen</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <!-- Radio-Button-Gruppe für die Fahrzeugauswahl -->
          <v-radio-group v-model="selectedVehicleId">
            <v-row>
              <v-col v-for="vehicle in vehicles" :key="vehicle.id" cols="12">
                <v-radio
                  :label="vehicle.name"
                  :value="vehicle.id"
                  @change="changeVehicle(vehicle)"
                ></v-radio>
              </v-col>
            </v-row>
          </v-radio-group>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>

    <!--Baugruppen-Navigation-Drawer-Rechts-->
    <v-navigation-drawer
      app
      v-model="baugruppendrawer"
      touchless
      right
      :mobile-breakpoint="0"
      :width="$vuetify.breakpoint.xs ? '100%' : '25%'"
    >
      <v-card flat class="pb-16">
        <v-card-title class="pa-0">
          <v-toolbar dark dense color="primary" elevation="0">
            <v-btn icon dark @click="closeBaugruppendrawer">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Baugruppe auswählen</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-sheet class="pa-4 primary lighten-2">
          <v-text-field
            v-model="baugruppendrawersearch"
            label="Baugruppe suchen"
            dark
            flat
            solo-inverted
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
          ></v-text-field>
        </v-sheet>
        <v-card-text class="my-list">
          <v-treeview
            ref="tree"
            :items="getTreeviewHierData"
            item-children="treeviewchildren"
            :search="baugruppendrawersearch"
            open-all
            :open.sync="baugruppentreeopen"
          >
          </v-treeview>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>

    <!--Color-Navigation-Drawer-Rechts-->
    <v-navigation-drawer
      app
      v-model="colordrawer"
      touchless
      :mobile-breakpoint="0"
      right
      :width="$vuetify.breakpoint.xs ? '100%' : '25%'"
    >
      <v-card flat height="100%">
        <v-card-title class="pa-0">
          <v-toolbar dark dense color="primary" elevation="0">
            <v-btn icon dark @click="colordrawer = !colordrawer">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Farbe auswählen</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip
              bottom
              :disabled="$vuetify.breakpoint.mobile ? true : false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="farbe_aufsaugen"
                >
                  <v-icon>mdi-eyedropper</v-icon>
                </v-btn>
              </template>
              <span>Farbe aufsaugen</span>
            </v-tooltip>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-btn block @click="modellBuntLackieren">Moped bunt lackieren</v-btn>
          <v-color-picker
            v-model="bblackierfarbe"
            @update:color="farbegeandert"
            class="ma-2"
            show-swatches
            swatches-max-height="200px"
          ></v-color-picker>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>

    <!-- Hauptinhalt -->
    <v-main>
      <div class="vieww">
        <!-- Hier können Sie den Hauptinhalt Ihrer Seite platzieren -->
        <div class="child2">
          <webvis-viewer viewer="3dview" context="myContext"></webvis-viewer>
        </div>
      </div>
    </v-main>

    <v-bottom-navigation
      :value="bbselectedfunction"
      color="primary"
      grow
      class="tabbar"
      :width="
        colordrawer || baugruppendrawer || fahrzeugdrawer ? '75%' : '100%'
      "
    >
      <v-btn
        active-class="bbarinactive"
        @click="
          anzzurueck > 0 ? resetdialog = true : null
        "
      >
        <span>Reset</span>
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-btn active-class="bbarinactive" @click="clickwebviszurueck">
        <span>Zurück</span>
        <v-badge :content="anzzurueck" :value="anzzurueck" color="green" inline>
          <v-icon>mdi-arrow-left</v-icon>
        </v-badge>
      </v-btn>
      <v-btn active-class="bbarinactive" @click="clickzentrieren">
        <span>Zentrieren</span>
        <v-icon>mdi-crosshairs-gps</v-icon>
      </v-btn>
      <v-menu open-on-hover top offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark v-bind="attrs" v-on="on">
            <span :style="{ color: '#1867C0' }"
              >{{ bbnavigatetitle }}
              <v-icon small color="primary">mdi-menu-down</v-icon>
            </span>
            <v-icon :color="getbbnavcolor">{{ bbnavigateicon }}</v-icon>
          </v-btn>
        </template>

        <v-list dense nav rounded>
          <v-list-item-group v-model="bbnavigateactiveitem" color="primary">
            <v-list-item
              v-for="(item, i) in menuitemsnavigate"
              :key="i"
              @click="menuitemsnavigatechanged(i)"
              link
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-bottom-navigation>

    <v-snackbar app v-model="snackbar" top :timeout="snackbarTimeout" color="orange darken-2">
      <strong>{{ snackbartext }}</strong>

      <!--<template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>-->
    </v-snackbar>
    <v-dialog v-model="resetdialog" max-width="290" persistent>
      <v-card>
        <v-card-title class="text-h5"> Hinweis </v-card-title>

        <v-card-text>
          Du hast dein Modell konfiguriert. Möchtest du alle Änderungen
          rückgängig machen?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="resetdialog = false">
            Abbrechen
          </v-btn>

          <v-btn color="green darken-1" text @click="resetdialog = false; clickwebvisreset();">
            Zustimmen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
///* global webvis */
import { moped3d_api_neu } from "./moped3d_api_neu.js";
const m3d = new moped3d_api_neu();

export default {
  name: "App",

  data: () => ({
    //bottombar-data
    menuitemsnavigate: [
      { title: "Ersatzteil (soon)", icon: "mdi-information-outline" },
      { title: "Lackieren", icon: "mdi-spray", icon_bb: "mdi-circle" },
      { title: "Baugruppe auswählen", icon: "mdi-eye" },
      { title: "Baugruppe ausblenden", icon: "mdi-eye-off" },
      { title: "Einzelteil auswählen", icon: "mdi-eye" },
      { title: "Einzelteil ausblenden", icon: "mdi-eye-off" },
    ],
    bbnavigateicon: "",
    bbnavigatetitle: "",
    bbnavigateactiveitem: 2,
    bbselectedfunction: 3,
    bblackierfarbe: "#DF8A0C",
    farberesetmodus: false, //aktion_id = 8 - keine lackierfarbe ausgewählt, also standard wiederherstellen
    anzzurueck: 0,
    leftNavigationDrawer: false,
    fahrzeugdrawer: false,
    baugruppendrawer: false,
    colordrawer: false,
    vehicles: [],
    selectedAssemblies: [],
    assemblyTree: {},
    selectedVehicleId: null, // Um die ausgewählte Fahrzeug-ID zu verfolgen
    baugruppendrawersearch: null,
    baugruppentreeopen: [],

    snackbar: false,
    snackbartext: "Hi",
    snackbarTimeout: 5000,
    resetdialog: false,

    //weitere Funktionen-data
    menuitemsweiterefunk: [
      { title: "2d Ansicht", icon: "mdi-video-2d" },
      { title: "Screenshot", icon: "mdi-camera" },
      { title: "Teilen", icon: "mdi-share" },
    ],
  }),
  created() {
    this.bbnavigateactiveitem = 2;
    this.bbnavigateicon = "mdi-eye";
    this.bbnavigatetitle = "Baugruppe";

    var _this = this;

    window.addEventListener("webvisfinished", function () {
      _this.snackbar = false;
    });

    window.addEventListener("m3d_anzzurueck_changed", function () {
      _this.anzzurueck = m3d.getAnzZurueck();
      m3d.isInInitSelectionTV = true;
      /*setTimeout(() => {
        _this.selectedAssemblies = m3d.m3d_data.getEnabledLetzteEbeneBaugruppen();
      }, 1000);*/
    });

    window.addEventListener("m3d_farbe_aufgesaugt", function () {
      _this.farberesetmodus = true;
      _this.bblackierfarbe = "#" + m3d.aktiveFunktion.neuerWert;
      console.log(_this.bblackierfarbe);
      _this.snackbartext = "Farbe aufgesaugt - jetzt lackieren!";
      _this.snackbarTimeout = 5000;
      _this.snackbar = true;
    });
  },
  async mounted() {
    this.snackbartext = "Loading ...";
    this.snackbarTimeout = -1;
    this.snackbar = true;
    await m3d.start();
    this.bbnavigateactiveitem = 2;
    this.vehicles = m3d.m3d_data.alleFahrzeuge;
    this.selectedVehicleId = m3d.m3d_data.aktFzg.id;
    this.assemblyTree = m3d.m3d_data.fzgHierTree;

    m3d.isInInitSelectionTV = true;
    //this.selectedAssemblies = m3d.m3d_data.getLetzteEbeneBaugruppen();
  },
  methods: {
    menuitemsnavigatechanged(menuid) {
      this.bbnavigateactiveitem = menuid;
      this.bbnavigateicon = this.menuitemsnavigate[menuid].icon_bb
        ? this.menuitemsnavigate[menuid].icon_bb
        : this.menuitemsnavigate[menuid].icon;
      this.bbnavigatetitle = this.menuitemsnavigate[menuid].title.split(" ")[0];

      switch (menuid) {
        case 1:
          m3d.changeFunktion(1, this.bblackierfarbe.substring(1));
          this.fahrzeugdrawer = this.baugruppendrawer = false;
          this.colordrawer = true;
          break;
        case 2:
          m3d.changeFunktion(2, null);
          break;
        case 3:
          m3d.changeFunktion(3, null);
          break;
        case 4:
          m3d.changeFunktion(4, null);
          break;
        case 5:
          m3d.changeFunktion(5, null);
          break;
      }

      if (menuid !== 1) {
        this.colordrawer = false;
      }
    },
    farbegeandert() {
      m3d.changeFunktion(1, this.bblackierfarbe.substring(1));
    },
    clickwebviszurueck() {
      m3d.doFunktionZurueck();
    },
    clickwebvisreset() {
      m3d.resetChanges();
    },
    clickzentrieren() {
      m3d.doZentrieren();
    },
    farbe_aufsaugen() {
      if (this.$vuetify.breakpoint.xs) {
        this.colordrawer = false;
      }

      this.snackbartext = "Jetzt Farbe aufsaugen!";
      this.snackbarTimeout = 5000;
      this.snackbar = true;
      m3d.changeFunktion(7);
    },
    modellBuntLackieren() {
      m3d.changeFunktion(6);

      this.farbegeandert();

      if (this.$vuetify.breakpoint.xs) {
        this.colordrawer = false;
      }
    },
    menuitemsweiterefunkchanged(index) {
      this.selectedAssemblies = [];
      console.log(this.menuitemsweiterefunk[index].title + " clicked");
    },
    alleNavDrawerSchliessen() {
      this.leftNavigationDrawer =
        this.fahrzeugdrawer =
        this.baugruppendrawer =
        this.colordrawer =
          false;
    },
    closeBaugruppendrawer() {
      this.alleNavDrawerSchliessen();
      setTimeout(() => {
        m3d.doZentrieren();
      },1000);
    },
    clickFahrzeugAuswaehlen() {
      this.alleNavDrawerSchliessen();
      this.fahrzeugdrawer = true;
    },
    async clickBaugruppenAuswaehlen() {
      this.alleNavDrawerSchliessen();

      if (!this.selectedVehicleId) {
        return;
      }

      this.baugruppentreeopen = [this.getTreeviewHierData[0].id]; //erste Ebene expandieren
      this.baugruppendrawersearch = "";
      this.baugruppendrawer = true;
    },
    clickEinstellungenAuswaehlen() {},

    async changeVehicle(fzg) {
      this.alleNavDrawerSchliessen();
      this.snackbartext = "Loading ...";
      this.snackbarTimeout = -1;
      this.snackbar = true;
      await m3d.changeModell(fzg);
      this.selectedVehicleId = m3d.m3d_data.aktFzg.id;
      this.assemblyTree = m3d.m3d_data.fzgHierTree;

      m3d.isInInitSelectionTV = true;
      /*setTimeout(() => {
        this.selectedAssemblies = m3d.m3d_data.getLetzteEbeneBaugruppen();
      }, 1000);*/
    },
    arraydiff(arr1, arr2) {
      const added = arr2.filter((x) => !arr1.includes(x));
      const deleted = arr1.filter((x) => !arr2.includes(x));
      return { added, deleted };
    },
    onTreeviewUpdated() {},
    getAncestors(node, id) {
      if (node.id === id) {
        return [node];
      }
      if (node.children) {
        for (const child of node.children) {
          const ancestors = this.getAncestors(child, id);
          if (ancestors) {
            return ancestors.concat(node);
          }
        }
      }
      return null;
    },
    findCommonAncestor(tree, childrenIds) {
      let allAncestors = childrenIds.map((id) => this.getAncestors(tree, id));
      let commonAncestor = null;
      for (let i = 0; i < allAncestors[0].length; i++) {
        const ancestor = allAncestors[0][i];
        if (allAncestors.every((ancestors) => ancestors.includes(ancestor))) {
          commonAncestor = ancestor;
          break;
        }
      }
      return commonAncestor;
    },
    tvSelectionChanged(status, clickedID) {
      /*if (m3d.isInInitSelectionTV === true) {
        m3d.isInInitSelectionTV = false;
        return;
      }*/

      if (status === "added") {
        m3d.showBaugruppenById([clickedID]);
      } else if (status === "deleted") {
        m3d.hideBaugruppenById([clickedID]);
      }
      setTimeout(() => {
      m3d.doZentrieren();
      },500);
    },
  },
  computed: {
    getbbnavcolor() {
      return this.bbnavigateicon == "mdi-circle"
        ? this.bblackierfarbe
        : "#1867C0";
    },
    color: {
      get() {
        return this["hex"];
      },
      set(v) {
        this["hex"] = v;
      },
    },
    getTreeviewHierData() {
      return [this.assemblyTree];
    },
  },
  watch: {
    baugruppendrawersearch() {
      if (this.baugruppendrawersearch !== null) {
        this.baugruppentreeopen = m3d.m3d_data.baugrIds;
      }
      if (
        this.baugruppendrawersearch === "" ||
        (this.baugruppendrawersearch === null &&
          this.getTreeviewHierData.length !== 0)
      ) {
        this.baugruppentreeopen = [this.getTreeviewHierData[0].id]; //erste Ebene expandieren
      }
    },
    // selectedAssemblies(newVal, oldVal) {
      // if (m3d.isInInitSelectionTV === true && newVal.length === 0) return;

      // var changedBaugruppenIds = [];
      // var clickedBaugrId = null;
      // if (newVal.length < oldVal.length) {
      //   changedBaugruppenIds = oldVal.filter((x) => !newVal.includes(x));
      //   clickedBaugrId = this.findCommonAncestor(
      //     this.getTreeviewHierData[0],
      //     changedBaugruppenIds
      //   );
      //   this.tvSelectionChanged("deleted", clickedBaugrId.id);
      // } else if (oldVal.length < newVal.length) {
      //   changedBaugruppenIds = newVal.filter((x) => !oldVal.includes(x));
      //   clickedBaugrId = this.findCommonAncestor(
      //     this.getTreeviewHierData[0],
      //     changedBaugruppenIds
      //   );
      //   this.tvSelectionChanged("added", clickedBaugrId.id);
      // }
    // },
  },
};
</script>

<style>
html {
  padding-top: env(safe-area-inset-top);
  min-height: 100%; /* Look, it's not fixed anymore! */
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
}

body {
  padding-bottom: env(safe-area-inset-bottom); /* iOS 11.2+ */
  height: 100vh;
  margin: 0;
  overflow-y: scroll;
  overscroll-behavior-y: contain; /* Verhindert das "Bouncing" am Ende des Bildlaufs */
}

.v-navigation-drawer__content {
  overflow: hidden !important;
}

.v-treeview-node__label {
  overflow: initial;
  text-overflow: initial;
}

.my-list {
  height: calc(100vh - 200px);
  overflow: auto;
}
.vieww {
  display: flex;
  flex-flow: column nowrap;
  margin: 0px;
  height: 100%;
}
.child2 {
  flex-grow: 1;
}
.tabbar {
  position: fixed;
  bottom: 0;
  bottom: env(safe-area-inset-bottom);
}
.bbarinactive {
  color: rgba(0,0,0,0.54) !important;
}
.bbarinactive.before {
  opacity: 0 !important;
}
.red_list .v-list-item-group .v-list-item--active {
  color: #757575;
}

.theme--light.v-list-item--active:before {
  opacity: 0;
}
</style>

