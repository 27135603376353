import { m3d_data_fetcher } from "./m3d_data_fetcher";
import { Node, Tree } from './treescript.js';

class m3d_data_handler {

    m3d_fetcher = new m3d_data_fetcher();

    alleFahrzeuge = [];
    aktFzg = {};

    fzgHierarchie = [];
    baugrIds = [];
    nichtSelektierbarArr = [];
    fzgHierTreeObj = null;
    fzgHierTree = [];



    constructor() {
    }

    async start() {
        this.alleFahrzeuge = await this.m3d_fetcher.getFahrzeuge();
        return this.alleFahrzeuge; //müssen wir das zurückgeben, damit mit await auf die start funktion gewartet wird?
    }

    getInitialFahrzeug() {
        var initFzg = this.alleFahrzeuge.filter(fzg => fzg.name === 'S51 Vergaser')[0];
        if (initFzg !== undefined) {
            return initFzg;
        }

        return null;
    }

    async changeModell(newFzg) {
        this.aktFzg = newFzg;
        (
            {
                hierData: this.fzgHierarchie,
                baugrIds: this.baugrIds,
                baugrWebvisHandle: this.baugrWebvisHandle,
                nichtSelektierbarArr: this.nichtSelektierbarArr
            } =
            await this.m3d_fetcher.getHierarchie(this.aktFzg.id)
        );

        this.loadTree();
    }

    loadTree() {

        let nodesArr = [];
        for (let i = 0; i < this.fzgHierarchie.length; i++) {
            nodesArr.push(new Node(this.fzgHierarchie[i]));
        }

        this.fzgHierTreeObj = new Tree(nodesArr)
        this.fzgHierTree = this.fzgHierTreeObj.root;
    }

    getAlleBaugruppen(){

        return this.fzgHierTreeObj.getAlleBaugruppen();

    }

    getLetzteEbeneBaugruppen(){

        return this.fzgHierTreeObj.getLetzteEbeneBaugruppen().alle;

    }

    getEnabledLetzteEbeneBaugruppen(){

        return this.fzgHierTreeObj.getLetzteEbeneBaugruppen().enabled;

    }

    getNodeidsByBaugrids(baugridArr){

        var nodeidArr = [];

        for (let i = 0; i < baugridArr.length; i++) {
            nodeidArr.push(this.fzgHierTreeObj.findNode('id', baugridArr[i]).webvisnode);
        }

        return nodeidArr;
    }

    setEnabledStatusByWebvisArr(nodeArr, status){
        this.fzgHierTreeObj.updateEnabledStatusByWebvisArr(nodeArr, status);
    }

    getAlleTreeNodes(){
        return this.fzgHierTreeObj.nodes;
    }

    extractValue(arr, prop) {

        // extract value from property
        let extractedValue = arr.map(item => item[prop]);
    
        return extractedValue;
    
    }

    getNichtSelektierbarNodeArr(){

        var nodes = [];

        for (let i = 0; i < this.fzgHierTreeObj.nodes.length; i++) {
            if (this.fzgHierTreeObj.nodes[i].nichtauswaehlbar) {
                nodes.push(this.fzgHierTreeObj.nodes[i].webvisnode);
            }
            
        }

        return nodes;

    }

}

export { m3d_data_handler };
