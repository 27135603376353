import axios from 'axios';

class m3d_data_fetcher{

    async getFahrzeuge(){
        var fahrzeugeArr = (await axios.get("https://api.moped3d.de/fahrzeuge")).data;

        return fahrzeugeArr;
    }

    async getHierarchie(fzgId){
        var hierArr = (await axios.get(`https://api.moped3d.de/hierarchie/${fzgId}`)).data;

        return { 
            hierData: hierArr.erg, 
            baugrIds: hierArr.baugrIds, 
            baugrWebvisHandle: hierArr.baugrWebvisHandle, 
            nichtSelektierbarArr: hierArr.nichtselektierbar 
        };
    }

}

export { m3d_data_fetcher };
