import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from 'axios'

Vue.prototype.$http = axios;
//Vue.config.productionTip = false

const globalVar = Vue.observable({
    whydoesitfeelike: ""
});
Object.defineProperty(Vue.prototype, '$globalVar', {
    get() {
        return globalVar.whydoesitfeelike;
    },
    set(value) {
        globalVar.whydoesitfeelike = value;
    }
});

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
