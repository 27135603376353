
import { webvis_controller } from './webvis_controller.js';
import { m3d_data_handler } from './m3d_data_handler.js';

const aktion_lackieren = 1;
const aktion_baugruppen_auswaehlen = 2;
const aktion_baugruppen_ausblenden = 3;
const aktion_einzelteil_auswaehlen = 4;
const aktion_einzelteil_ausblenden = 5;
const aktion_bunt_lackieren = 6;
const aktion_farbe_aufsaugen = 7;
const aktion_reset_farbe = 8;

const aktionen_arr = [
    { "id": 0, "name": "dummy"},
    { "id": 1, "name": "lackieren", "prop": "appearanceURI", "neuerWert": "", "alterWert": "" },
    { "id": 2, "name": "baugruppen_auswaehlen", "prop": "enabled", "neuerWert": false, "alterWert": true },
    { "id": 3, "name": "baugruppen_ausblenden", "prop": "enabled", "neuerWert": false, "alterWert": true },
    { "id": 4, "name": "einzelteil_auswaehlen", "prop": "enabled", "neuerWert": false, "alterWert": true },
    { "id": 5, "name": "einzelteil_ausblenden", "prop": "enabled", "neuerWert": false, "alterWert": true },

    { "id": 6, "name": "modell_bunt_faerben", "prop": "colorizeEffect", "neuerWert": true, "alterWert": false },

    { "id": 7, "name": "farbe_aufsaugen", "prop": "appearanceURI" },
    { "id": 8, "name": "reset_farbe", "prop": "appearanceURI", "neuerWert": "", "alterWert": "" },
    { "id": 9, "name": "baugruppen_anzeigen", "prop": "enabled", "neuerWert": true, "alterWert": false },
    { "id": 10, "name": "baugruppen_hide", "prop": "enabled", "neuerWert": false, "alterWert": true },

];

class moped3d_api_neu {
    m3d_webvis = new webvis_controller();
    m3d_data = new m3d_data_handler();

    aktiveFunktion = aktionen_arr[4];
    alleAenderungen = [];

    modellLoading = false;

    isInInitSelectionTV = false;

    s51_vergaser_startEinst = {
        viewMatrix: [
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            0.00759888906031847,
            -0.017980754375457764,
            -0.09674429893493652,
            1
        ]
    };

    sr2_startEinst = {
        viewMatrix: [
            0.6729640364646912,
            -0.3423927128314972,
            -0.6556519865989685,
            0,
            -0.6860679984092712,
            0.042353879660367966,
            -0.7262997627258301,
            0,
            0.27644988894462585,
            0.9386007785797119,
            -0.20640185475349426,
            0,
            -0.356004923582077,
            0.2514103353023529,
            -3.2926454544067383,
            1
        ]
    };
    kr51_startEinst = {
        colorEinst: [{
            handleMap: "/{0}/{0}/[0]",
            color: '000000'
          }, {
            handleMap: "/{0}/{761}/[0]",
            color: '000000'
          }, {
            handleMap: "/{0}/{646}/[0]",
            color: '000000'
          }, {
            handleMap: "/{0}/{376}/[0]",
            color: '000000'
          }, {
            handleMap: "/{0}/{377}/[0]",
            color: '000000'
          }, {
            handleMap: "/{0}/{625}/[0]",
            color: '000000'
          }, {
            handleMap: "/{0}/{535}/[0]",
            color: '000000'
          }, {
            handleMap: "/{0}/{616}/[0]",
            color: '000000'
          }, {
            handleMap: "/{0}/{617}/[0]",
            color: '000000'
          }, {
            handleMap: "/{0}/{532}/[0]",
            color: '000000'
          }, {
            handleMap: "/{0}/{1634}/[0]",
            color: 'ffffff'
          }],
        viewMatrix: [
            -0.641638994216919,
            0.028480639681220055,
            -0.7664741277694702,
            0,
            -0.10134785622358322,
            0.987398087978363,
            0.1215309202671051,
            0,
            0.7602790594100952,
            0.15566033124923706,
            -0.6306668519973755,
            0,
            0.14045259356498718,
            -0.15776440501213074,
            -4.1662726402282715,
            1
        ]
    };

    constructor() {

    }

    async start() {
        await this.m3d_webvis.start();
        await this.m3d_data.start();
        var initFzg = this.m3d_data.getInitialFahrzeug();
        this.registerWebvisEvent();
        await this.changeModell(initFzg);
        this.changeFunktion(aktion_baugruppen_auswaehlen);
    }

    async changeModell(newFzg){
        this.alleAenderungen = [];
        this.wvinitialisiert = false;
        this.modellLoading = true;
        window.dispatchEvent(new Event("m3d_anzzurueck_changed"));
        await this.m3d_data.changeModell(newFzg);

        var fzgStartEinst = {};
        if(newFzg.url === "https://www.moped3d.de/modelle/sr2/sr2e.x3d")
            fzgStartEinst = this.sr2_startEinst;
        else if(newFzg.url === "https://www.moped3d.de/modelle/schwalbe/schwalbe.x3d")
            fzgStartEinst = this.kr51_startEinst;
        else if(newFzg.url === "https://www.moped3d.de/modelle/s51_vergaser.x3d")
            fzgStartEinst = this.s51_vergaser_startEinst;

        await this.m3d_webvis.changeModell(newFzg.url, fzgStartEinst);
    }

    changeFunktion(neueFunktion, neueFarbe) {
        var selection_color = '';

        this.aktiveFunktion = aktionen_arr[neueFunktion];
        if (this.aktiveFunktion.id === aktion_lackieren) {
            this.aktiveFunktion.neuerWert = selection_color = neueFarbe;
        }
        else if (this.aktiveFunktion.id === aktion_bunt_lackieren) {
            this.doFunktion([]);
        }
        else if (this.aktiveFunktion.id === aktion_reset_farbe) {
            this.aktiveFunktion.neuerWert = 'AAAAAAFF'
            selection_color = 'FFEB3BFF';
        }

        this.m3d_webvis.changeSetting("selectionColor", selection_color);
        this.m3d_webvis.changeSetting("preSelectionColor", selection_color);
    }

    async doFunktion(nodes) {

        if(this.modellLoading === true)
            return;

        var aktiveFunktionTmp = { ...this.aktiveFunktion };

        if (aktiveFunktionTmp.id === aktion_farbe_aufsaugen) {
            var aufgesaugteFarbe = await this.m3d_webvis.getProperty(nodes[0], aktiveFunktionTmp.prop);
            //wenn ein nicht lackiertes Teil ausgewählt wurde, dann reset appearanceURI durchführen
            if (aufgesaugteFarbe === undefined) {
                this.changeFunktion(7);
            }
            else {
                this.changeFunktion(1, aufgesaugteFarbe);
            }
            window.dispatchEvent(new Event("m3d_farbe_aufgesaugt"));
            return;
        }

        if (aktiveFunktionTmp.id === aktion_lackieren) {

            //die farbe wird nicht lackiert, wenn harlekin an ist,
            //deswegen harlekin ausschalten und dann lackieren
            var indexAenderung = this.alleAenderungen.findIndex((obj) => obj.id === aktion_bunt_lackieren);
            if (indexAenderung !== -1)
                await this.doFunktionZurueck(indexAenderung);

            aktiveFunktionTmp.alterWert = await this.m3d_webvis.getProperty(nodes[0], aktiveFunktionTmp.prop);

            //wenn die gleiche Farbe noch einmal lackiert werden soll abbrechen
            if (aktiveFunktionTmp.alterWert === aktiveFunktionTmp.neuerWert) {
                return;
            }
        }

        if (aktiveFunktionTmp.id === aktion_reset_farbe) {
            aktiveFunktionTmp.alterWert = await this.m3d_webvis.getProperty(nodes[0], aktiveFunktionTmp.prop);
        }


        if (aktiveFunktionTmp.id === aktion_bunt_lackieren) {
            this.m3d_webvis.changeSetting(aktiveFunktionTmp.prop, aktiveFunktionTmp.neuerWert);
        }
        else if (aktiveFunktionTmp.id === aktion_reset_farbe) {
            this.m3d_webvis.resetProperty(nodes, aktiveFunktionTmp.prop);
        }
        else if(aktiveFunktionTmp.id === aktion_einzelteil_ausblenden){
            nodes = await this.m3d_webvis.hideEin();
        }
        else if(aktiveFunktionTmp.id === aktion_einzelteil_auswaehlen){
            nodes = await this.m3d_webvis.showEin();
            this.doZentrieren();
        }
        else if(aktiveFunktionTmp.id === aktion_baugruppen_ausblenden){
            nodes = await this.m3d_webvis.hideBaugr();
        }
        else if(aktiveFunktionTmp.id === aktion_baugruppen_auswaehlen){
            nodes = await this.m3d_webvis.showBaugr();
            /*if( this.m3d_webvis.isLetzteBaugruppe() )
                this.doZentrieren();*/
        }
        else {
            nodes = await this.m3d_webvis.hideEinzelteil();
            await this.m3d_webvis.changeProperty(nodes, aktiveFunktionTmp.prop, aktiveFunktionTmp.neuerWert);
        }

        setTimeout(() => {
            this.m3d_webvis.removeSelection();
        }, 100);

        //es hat sich nichts geändert...
        if(nodes === null && aktiveFunktionTmp.id !== aktion_bunt_lackieren) return;

        aktiveFunktionTmp.viewMatrix = await this.m3d_webvis.getViewMatrix();

        aktiveFunktionTmp.nodeArr = nodes;
        this.alleAenderungen.push({ ...aktiveFunktionTmp });

        if(aktiveFunktionTmp.id === aktion_einzelteil_ausblenden ||
            aktiveFunktionTmp.id === aktion_einzelteil_auswaehlen ||
            aktiveFunktionTmp.id === aktion_baugruppen_ausblenden ||
            aktiveFunktionTmp.id === aktion_baugruppen_auswaehlen ){
            //this.setStatusNodesInTree(aktiveFunktionTmp.nodeArr, aktiveFunktionTmp.neuerWert)
        }

        window.dispatchEvent(new Event("m3d_anzzurueck_changed"));
    }

    async doFunktionZurueck(indexAenderung) {

        var aktZurueck =
            (indexAenderung !== undefined && indexAenderung !== -1) ?
                this.alleAenderungen.splice(indexAenderung, 1)[0] :
                this.alleAenderungen.pop();

        if (aktZurueck.id === aktion_bunt_lackieren) {
            this.m3d_webvis.changeSetting(aktZurueck.prop, aktZurueck.alterWert);
        }
        else if(aktZurueck.id === aktion_lackieren || aktZurueck.id === aktion_reset_farbe){
            await this.m3d_webvis.changeProperty(aktZurueck.nodeArr, aktZurueck.prop, aktZurueck.alterWert);
        }
        else {
            //alles andere ist hoffentlich nodes ein- und ausblenden
            await this.m3d_webvis.changeProperty(aktZurueck.nodeArr, aktZurueck.prop, aktZurueck.alterWert);
            this.m3d_webvis.setViewMatrix(aktZurueck.viewMatrix);
            this.setStatusNodesInTree(aktZurueck.nodeArr, aktZurueck.alterWert)
        }


        window.dispatchEvent(new Event("m3d_anzzurueck_changed"));
    }

    setStatusNodesInTree(nodesArr, status){
        this.m3d_data.setEnabledStatusByWebvisArr(nodesArr, status);
    }

    async resetChanges() {
        await this.m3d_webvis.resetChanges();
        this.alleAenderungen = [];

        window.dispatchEvent(new Event("m3d_anzzurueck_changed"));
    }

    doZentrieren() {
        this.m3d_webvis.doZentrieren();
    }

    getAnzZurueck() {
        return this.alleAenderungen.length;
    }

    getEnabledBaugruppen(){
        return this.m3d_data.getEnabledLetzteEbeneBaugruppen();
    }

    async call_wv_after_rend_finished(){
        //die baugruppen werden per referenz übergeben, deswegen müssen die nodeids nicht zurück gegeben werden
        await this.m3d_webvis.getNodeidForHandleMapArr(this.m3d_data.getAlleTreeNodes());

        await this.m3d_webvis.setNichtselektierbarProp(this.m3d_data.getNichtSelektierbarNodeArr());
    }

    registerWebvisEvent(){

        var neuesThis = this;
        window.addEventListener("webvis_nodeclicked", function () {
            neuesThis.doFunktion([neuesThis.m3d_webvis.clickedNode]);
        });

        window.addEventListener("webvisfinished", function () {
            neuesThis.call_wv_after_rend_finished();
            neuesThis.modellLoading = false;
        });
    }

    async hideBaugruppenById(baugrIdArr){
        var nodeidArr = this.m3d_data.getNodeidsByBaugrids(baugrIdArr);
        var ausgeblendeteNodeids = await this.m3d_webvis.hideNodesByNodeids(nodeidArr);
        this.m3d_webvis.gizmoNeuSetzen();
        var neueAktiveFunktion = aktionen_arr[3];
        neueAktiveFunktion.nodeArr = ausgeblendeteNodeids;
        this.alleAenderungen.push({...neueAktiveFunktion});

        this.setStatusNodesInTree(neueAktiveFunktion.nodeArr, false)

        window.dispatchEvent(new Event("m3d_anzzurueck_changed"));
    }

    async showBaugruppenById(baugrIdArr){
        var nodeidArr = this.m3d_data.getNodeidsByBaugrids(baugrIdArr);
        var eingeblendeteNodeids = await this.m3d_webvis.showNodesByNodeids(nodeidArr);
        this.m3d_webvis.gizmoNeuSetzen();
        var neueAktiveFunktion = aktionen_arr[2];
        neueAktiveFunktion.nodeArr = eingeblendeteNodeids;
        this.alleAenderungen.push({...neueAktiveFunktion});

        this.setStatusNodesInTree(neueAktiveFunktion.nodeArr, true)

        window.dispatchEvent(new Event("m3d_anzzurueck_changed"));
    }
}

export { moped3d_api_neu };
