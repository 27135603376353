/* global webvis */
/* global webvisUI */

class webvis_controller {
    ctx = null;
    viewer = null;
    rootNode = null;
    clickedNode = null;
    nodepathObj = {};
    glAnzBaugr = - 1;
    startEinstellung = {};

    async start() {
        this.viewer = await document
            .querySelector("[viewer='3dview']")
            .requestViewer();
        this.ctx = await this.viewer.getContext();
        //this.ctx = await webvis.getContext();
        webvisUI.setSetting("contextMenuEnabled",false);
        webvisUI.setSetting("labelTooltipEnabled",false);
        this.ctx.changeSetting(webvis.ViewerSettingStrings.EXPAND_ON_VIEWER_SELECTION, true);
        await this.ctx.registerCustomProperty("nichtselektierbar", false, true);

        this.registerEvent();

    }

    async changeModell(modellURL, fzgEinstellungen) {
        this.startEinstellung = fzgEinstellungen;
        await this.ctx.remove();
        this.rootNode = await this.ctx.add(modellURL, 0, 'open');
        await this.changeProperty(this.rootNode, "enabled", true);
        /*setTimeout(() => {
                this.viewer.fitViewToDirection(0);
        },1000);*/
        this.startAnsichtsetzen();
        this.doZentrieren();
        //console.log("webvis_wait_for_finish");
        await this.waitForRenderingFinished(this.ctx);

        //mal wieder ein workaround, weil bei x3d-Modellen die Baugruppen nicht in die nodes vom instancegraph geschrieben werden...
        if(modellURL === "https://www.moped3d.de/modelle/s51_vergaser.x3d")
            await this.waitFor(2000);
        const graph = webvis.getContext().getInstanceGraph()._nodes
        const alleParents = Object.values(graph).map(x=>x.parent);
        const allMissingParents = alleParents.filter((x,index)=>x!==undefined && Object.keys(graph).includes(x.toString())===false&&alleParents.indexOf(x)===index)
        await Promise.all(allMissingParents.map(x => webvis.getContext().getProperty(x,"label")));
        //workaround ende...

        this.nodepathObj = {};
        this.glAnzBaugr = - 1;
        this.createNodepathObjRek(Object.values(this.ctx.getInstanceGraph()._nodes)[1]);
        this.startPropertysetzen();
        //event absetzen, damit dem nutzer angezeigt wird, dass das Modell fertig geladen ist
        //es wird in der klasse m3d_api_neu darauf gehört
        window.dispatchEvent(new Event("webvisfinished"));
        
    }

    startAnsichtsetzen(){
        if(this.startEinstellung.viewMatrix)
            this.viewer.setViewMatrix(this.startEinstellung.viewMatrix);
        else
            this.viewer.fitViewToDirection(0);
    }

    startPropertysetzen(){
        if(this.startEinstellung.colorEinst===undefined) return;
        for (let i = 0; i < this.startEinstellung.colorEinst.length; i++) {
            this.changeProperty(
                [this.nodepathObj[this.startEinstellung.colorEinst[i].handleMap]],
                "appearanceURI",
                this.startEinstellung.colorEinst[i].color
            )           
        }
    }

    changeSelectionColor(color) {
        this.ctx.changeSetting("selectionColor", color);
        this.ctx.changeSetting("preSelectionColor", color);
    }

    async changeSetting(setting, value) {
        await this.ctx.changeSetting(setting, value);
    }

    async changeProperty(nodeArr, prop, value) {
        await this.ctx.setProperty(nodeArr, prop, value);

        //beim ein- und ausblenden immer zentrieren...
        if (prop === "enabled") 
            this.viewer.setCenterOfRotation()
            //await this.doZentrieren();
    }

    async resetProperty(nodeArr, prop) {
        await this.ctx.resetProperty(nodeArr, prop);
    }

    async getProperty(node, prop) {
        return await this.ctx.getProperty(node, prop);
    }

    async resetChanges() {
        await this.ctx.setProperty(this.rootNode, "appearanceURI"); //warum funktioniert kein resetProperty()?
        await this.changeProperty(this.rootNode, "enabled", true);
        this.startAnsichtsetzen()
        this.startPropertysetzen();
        //this.viewer.fitViewToDirection(0);
        await this.ctx.changeSetting("colorizeEffect", false);
    }

    async isLetzteBaugruppe(){
        var parentNode = await this.findParentBaugruppe(this.clickedNode);
        var childsOfBaugruppe = this.getChildren(parentNode).enabledChilds;
        var childsOfModell = this.getChildren(this.rootNode).enabledChilds;

        return childsOfBaugruppe.length === childsOfModell.length ? true : false;
    }

    doZentrieren() {
        this.viewer.fitView();
        this.viewer.setCenterOfRotation()
    }

    gizmoNeuSetzen(){
        this.viewer.setCenterOfRotation();
    }

    removeSelection() {
        this.ctx.clearSelection();
    }

    //Hilfsfunktionen für webvis
    async waitForRenderingFinished(context) {
        await this.ctx.waitFor('renderingFinished');
        let counter = 5;
        while (counter > 0) {
            if (this.isFinished(context)) {
                //this.log("waiting ...");
                await this.waitFor(50);
                counter--;
            }
            else {
                await this.waitFor(200);
                counter = 5;
            }
        }
        //console.log("Finished");
    }

    isFinished(context) {
        const anyCtx = context;
        const tracker = anyCtx.getStateTracker();
        const init = tracker.getStateCount(4) === 0;
        const cacheQuery = tracker.getStateCount(8) === 0 || tracker.getStateCount(8) === undefined;
        const rendering = tracker.getStateCount(11) === 0;
        const pc = anyCtx.getViewer().getMainApplication().getPipelineControl();
        const downloadsFinished =
            (tracker.getStateCount(10) === 0 || tracker.getStateCount(10) === undefined) &&
            (tracker.getStateCount(9) === 0 || tracker.getStateCount(9) === undefined) &&
            anyCtx._registry.progressAPI.numberOfDownloads === 0 &&
            pc.getLastChangeFrameNumber() <= pc.getCurrentFrame();
        if (init && cacheQuery && rendering && downloadsFinished) {
            return true;
        } else {
            return false;
        }
    }

    waitFor(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async setNichtselektierbarProp(allNodes) {
        /*var allNodes = [];

        var nodePathHandleMapArr =
            await this.ctx.requestNodePathHandleMap(handleMapArr);

        allNodes = Object.keys(nodePathHandleMapArr).map(key => nodePathHandleMapArr[key].nodeID);*/

        await this.changeProperty(allNodes, "nichtselektierbar", true);

    }

    createNodepathObjRek(node){
        if(node.children.length === 0){
            this.nodepathObj[`/{0}/{${node.leafNodeRange[0]}}/[0]`] = node.id;
        }
        else{
            this.nodepathObj[`/{0}/${this.glAnzBaugr+=1}`] = node.id;
            for (let i = 0; i < node.children.length; i++) {
                this.createNodepathObjRek(this.ctx.getInstanceGraph()._nodes[node.children[i]])
            }
        }
        
    }

    async getNodeidForHandleMapArr(nodedataArr){

        for (let i = 0; i < nodedataArr.length; i++) {
            nodedataArr[i].webvisnode = this.nodepathObj[nodedataArr[i].webvispfad[0]];
        }

        return nodedataArr;
    }

    getEnabledChildren(iv_nodeid, result = []) {

        if (this.ctx.getInstanceGraph().getChildCount(iv_nodeid) === 0)
            return result;

        this.ctx.getInstanceGraph().getChildren(iv_nodeid).forEach((curr_node) => {
            this.ctx.getInstanceGraph().getChildCount(curr_node) > 0 ?
                this.getEnabledChildren(curr_node, result) :

                this.ctx.getInstanceGraph().isEnabled(curr_node) ?
                    result.push(curr_node) : void 0
        })

        return result;
    }


    //hier soll mal ein Object mit allen enabled/disabled Blättern zurückgegeben werden-nichtgetestet
    getChildren(iv_nodeid, result = { enabledChilds : [], disabledChilds: [], allChilds: []}) {

        if (this.ctx.getInstanceGraph().getChildCount(iv_nodeid) === 0)
            return result;

        this.ctx.getInstanceGraph().getChildren(iv_nodeid).forEach((curr_node) => {
            this.ctx.getInstanceGraph().getChildCount(curr_node) > 0 ?
                this.getChildren(curr_node, result) :

                (//else
                    result.allChilds.push(curr_node),
                    this.ctx.getInstanceGraph().isEnabled(curr_node) ?
                        result.enabledChilds.push(curr_node) : 
                        result.disabledChilds.push(curr_node)
                )
        })

        return result;
    }

    async showEin() {
        var a = await this.showEinzelteil();
        if(a.length === 0) return null;

        await this.changeProperty(a, "enabled", false);
        return a;
    }

    async hideEin() {
        var a = await this.hideEinzelteil();

        await this.changeProperty(a, "enabled", false);
        return a;
    }

    async getNodeidForWebvispfad(nodePathString){
        const currentNodeID = (await this.ctx.requestNodePathHandleMap([ nodePathString ]))[nodePathString].nodeID;
        return currentNodeID;
    }

    getAllNodeidsByEnabledState(node, stateToCheck){
        const queue = [node];
        let currentNodeID,
        enabledState;
        let allNodes = [];
        while (queue.length) {
            currentNodeID = queue.shift()
            enabledState = this.ctx.getInstanceGraph().getEnabledState(currentNodeID);
            var anz = webvis.getContext().getInstanceGraph().getCurrentChildren(currentNodeID).length;
            if (anz !== 0) {
                queue.push(...this.ctx.getInstanceGraph().getCurrentChildren(currentNodeID));
            } else if(enabledState == stateToCheck){
                allNodes.push(currentNodeID);
            }
        }

        return allNodes;
    }

    async showBaugruppeByWebvispfad(webvispfad){
        let nodeid = await this.getNodeidForWebvispfad(webvispfad);
        let nodes = this.getAllNodeidsByEnabledState(nodeid,0);
        this.changeProperty(nodes,"enabled",true)

        return nodes;
    }

    async hideBaugruppeByWebvispfad(webvispfad){
        let nodeid = await this.getNodeidForWebvispfad(webvispfad);
        let nodes = this.getAllNodeidsByEnabledState(nodeid,1);
        this.changeProperty(nodes,"enabled",false)

        return nodes;
    }

    async showBaugr() {
        var a = await this.getAlleBlaetterFuerShowBaugruppe();
            if(a.length === 0) return null;
        await this.changeProperty(a, "enabled", false);
        return a;
    }

    async hideBaugr() {
        var a = await this.getAlleBlaetterFuerHideBaugruppe();
        await this.changeProperty(a, "enabled", false);
        return a;
    }

    async showEinzelteil() {
        var nodesHideeinzelteil = await this.hideEinzelteil();
        var allEnabledNodes = this.getEnabledChildren(this.rootNode);        

        let allLeavesWithoutEinzelteil = allEnabledNodes.filter(x => !nodesHideeinzelteil.includes(x));
        if (allLeavesWithoutEinzelteil.length !== 0) {
            return allLeavesWithoutEinzelteil;
        }
        return [];
    }

    async hideEinzelteil() {
        var nichtSelectable = await this.getProperty(this.clickedNode, "nichtselektierbar");
        if (nichtSelectable) {
            var parent = this.ctx.getInstanceGraph().getParent(this.clickedNode);
            if (parent !== 0) {
                var children = this.ctx.getInstanceGraph().getChildren(parent);
                return children;
            }
        }

        return [this.clickedNode];

    }

    async findParents(node, result = []) {
        if (node === 0) return result.slice(1); //ohne ursprüngliche nodeid, auf die geklickt wurde

        var parentNode = this.ctx.getInstanceGraph().getParent(node);

        var nichtselektierbar = await this.getProperty(parentNode, "nichtselektierbar");
        if (nichtselektierbar !== 1)
            result.push(node);

        return this.findParents(parentNode, result);
    }

    async findParentBaugruppe(node) {

        var parentNode = this.ctx.getInstanceGraph().getParent(node);

        var nichtselektierbar = await this.getProperty(parentNode, "nichtselektierbar");
        if (nichtselektierbar !== 1)
            return node;
        else
            return this.ctx.getInstanceGraph().getParent(parentNode);
    }

    async getAlleBlaetterFuerHideBaugruppe() {
        //wenn die beiden folgenden Zeilen vertauscht werden, dann funktioniert es nicht mehr.
        //die Baugruppen werden noch nicht alle aufgelöst...
        //das attribut _nodes ist noch nicht komplett gefüllt
        let enabledNodesWithoutBaugruppe = await this.getAlleBlaetterFuerShowBaugruppe();
        var allEnabledNodes = this.getEnabledChildren(this.rootNode);
        let nodesFromBaugruppeToDisable =
            allEnabledNodes.length === enabledNodesWithoutBaugruppe.length ?
                allEnabledNodes :
                allEnabledNodes.filter(x => !enabledNodesWithoutBaugruppe.includes(x));
        if (nodesFromBaugruppeToDisable.length !== 0) {
            return nodesFromBaugruppeToDisable;
        }
        return null;
    }
    async getAlleBlaetterFuerShowBaugruppe() {
        let alleParents = await this.findParents(this.clickedNode);
        let meinArr = [];

        for (let i = 0; i < alleParents.length; i++) {
            meinArr.unshift(this.getEnabledChildren(alleParents[i]))
        }

        for (let j = 1; j <= meinArr.length; j++) {
            if (j === meinArr.length) {
                return [];
            }

            if (meinArr[j].length < meinArr[j - 1].length) {
                return meinArr[j - 1].filter(x => !meinArr[j].includes(x));
            }
        }
    }

    async hideNodesByNodeids(nodesArr){

        var disabledNodesArr = [];

        for (let i = 0; i < nodesArr.length; i++) {
            var nodes = this.getChildren(nodesArr[i]).enabledChilds;
            if (nodes.length !== 0) {
                disabledNodesArr.push(...nodes);
            }            
        }

        await this.changeProperty(disabledNodesArr, "enabled", false);

        return disabledNodesArr;
    }

    async showNodesByNodeids(nodesArr){

        var enabledNodesArr = [];

        for (let i = 0; i < nodesArr.length; i++) {
            var nodes = this.getChildren(nodesArr[i]).disabledChilds;
            if (nodes.length !== 0) {
                enabledNodesArr.push(...nodes);
            }            
        }

        await this.changeProperty(enabledNodesArr, "enabled", true);

        return enabledNodesArr;
    }

    async getViewMatrix(){
        return (await this.viewer.getViewMatrix());
    }

    setViewMatrix(matrix){
        this.viewer.setViewMatrix(matrix);
    }

    registerEvent() {
        var neuesThis = this;
        this.ctx.registerListener(
            // differnet eventTypes
            [webvis.EventType.NODE_CLICKED, webvis.EventType.SELECTION_CHANGED],

            // listener
            function (event) {
                // node added
                if (event.type == webvis.EventType.NODE_CLICKED) {
                    neuesThis.clickedNode = event.targetNodeID;
                    //console.log(webvis.getContext().getInstanceGraph());
                    //console.log(neuesThis.ctx.getInstanceGraph());

                    window.dispatchEvent(new Event("webvis_nodeclicked"));
                    //neuesThis.doFunktion([event.targetNodeID]);
                }
                else if (event.type == webvis.EventType.SELECTION_CHANGED)
                    neuesThis.removeSelection();
            },
            0,
            true
        );
    }

}

export { webvis_controller };
/*
var wv = new webvis_controller();
await wv.start();
await wv.changeModell("https://www.moped3d.de/modelle/Vergaser%20BVF%2016N1.iam");
//await wv.setNichtselektierbarProp(['/{0}/{15}/0']);

await wv.setNichtselektierbarProp(['/{0}/{15}/{0}/[0]']);*/

