// Klasse Node, die ein Objekt mit parent, children, nichtauswaehlbar, enabled, id und name repräsentiert  
class Node {
    constructor(obj) {
        this.parent = obj.parent_id; // der parent-Knoten oder null, wenn es keinen gibt  
        this.children = []; // ein Array von child-Knoten oder leer, wenn es keine gibt  
        this.treeviewchildren = []; // ein Array von child-Knoten oder leer, wenn es keine gibt  
        this.nichtauswaehlbar = obj.nichtselektierbar; // ein Boolean-Wert, der angibt, ob der Knoten ausgewählt werden kann oder nicht  
        this.enabled = obj.enabled !== undefined ? obj.enabled : true; // ein Boolean-Wert, der angibt, ob der Knoten aktiviert ist oder nicht, standardmäßig true  
        this.id = obj.id; // eine eindeutige Identifikationsnummer für den Knoten  
        this.webvisnode = obj.webvisnode; // eine eindeutige Identifikationsnummer für den Knoten 
        this.webvispfad = obj.webvis_pfad;
        this.name = obj.name; // ein Name für den Knoten  
    }
    // Methode, um den parent-Knoten zu ändern  
    setParent(newParent) {
        this.parent = newParent;
    }
    // Methode, um ein child-Knoten hinzuzufügen  
    addChild(newChild) {
        this.children.push(newChild);
    }

    addTreeviewChild(newChild) {
        this.treeviewchildren.push(newChild);
    }
    // Methode, um ein child-Knoten zu entfernen  
    removeChild(oldChild) {
        let index = this.children.indexOf(oldChild);
        if (index > -1) {
            this.children.splice(index, 1);
        }
    }
}

// Klasse Tree, die ein Array von Node-Objekten als Parameter nimmt und einen Baum daraus erstellt  
class Tree {
    nodes = null; // ein Array von Node-Objekten  
    root = null; // der Wurzel-Knoten des Baums 
    treeOhneBlaetter = []; 

    constructor(nodes) {
        this.nodes = nodes; // ein Array von Node-Objekten   
        this.buildTree(); // eine Hilfsmethode, die den Baum aus den Node-Objekten aufbaut  
    }
    // Hilfsmethode, die den Baum aus den Node-Objekten aufbaut  
    buildTree() {
        // finde den Wurzel-Knoten, der keinen parent hat  
        for (let node of this.nodes) {
            if (node.parent === null) {
                this.root = node;
                break;
            }
        }
        // verbinde die parent- und child-Knoten miteinander  
        for (let node of this.nodes) {
            if (node.parent !== null) {
                // finde den parent-Knoten im Array  
                let parentNode = this.findNode('id', node.parent);
                // füge den aktuellen Knoten als child-Knoten hinzu  
                parentNode.addChild(node);

                let childNode = this.findNode('parent', node.id);
                if (childNode && childNode.nichtauswaehlbar !== true) {
                    parentNode.addTreeviewChild(node)
                }
                
            }
        }
    }
    findNode(attr, key) {
        let node = this.nodes.find(n => n[attr] === key);
        if (node !== null) {
            return node;
        }
        return null;
    }
    
    //untersucht, ob die webvisid auswählbar ist, wenn nicht gibt sie alle blätter von der übergeordneten Baugruppe zurück 
    getwebvisidsByWebvisid(webvisnodeid) {
        let node = this.findNode('webvisnode', webvisnodeid);
        if (
            node !== null &&
            (node.nichtauswaehlbar === undefined ||
                node.nichtauswaehlbar !== undefined && node.nichtauswaehlbar === false)
        ) {
            return node.webvisnode;
        }
        let parentNode = this.findNode('id', node.parent);
        if (parentNode !== null) {
            let leaves = this.findAllLeaves(parentNode);
            if (leaves.length !== 0) {
                let webvisarr = [];
                for (let i = 0; i < leaves.length; i++) {
                    webvisarr.push(leaves[i].webvisnode);

                }
                return webvisarr;
            }
        }
        return null;
    }
    //sucht alle Blätter von einer Baugruppe 
    findAllLeaves(baugr) {
        let leaves = [];
        // überprüfe, ob der Knoten existiert  
        if (baugr !== null) {
            // verwende eine Warteschlange, um die Breitensuche durchzuführen  
            let queue = [baugr];
            while (queue.length > 0) {
                // nimm den ersten Knoten aus der Warteschlange  
                let node = queue.shift();
                // überprüfe, ob der Knoten ein Blatt ist  
                if (node.children.length === 0) {
                    // füge den Knoten zum Array hinzu  
                    leaves.push(node);
                }
                // füge die child-Knoten zur Warteschlange hinzu  
                for (let child of node.children) {
                    queue.push(child);
                }
            }
        }
        return leaves;
    }
    // Methode, die einen Blattknoten und einen enabled Status als Parameter bekommt und anschließend den enabled Status der parents aktualisiert  
    updateEnabledStatus(node, status) {
        // finde den Knoten im Baum, der das Blatt ist  
        //let node = this.findNode(leaf);  
        // überprüfe, ob der Knoten existiert und ein Blatt ist  
        if (node !== null && node.children.length === 0) {
            // ändere den enabled Status des Blattknotens  
            node.enabled = status;
            // finde alle parent-Knoten außer dem Root-Knoten  
            let parents = this.findParents(node);
            // für jeden parent-Knoten, ermittle den enabled Status, indem du prüfst, ob alle Blätter unter ihm enabled sind oder nicht  
            for (let parent of parents) {
                let status = this.checkEnabledStatus(parent);
                // ändere den enabled Status des parent-Knotens  
                parent.enabled = status;
            }
        }
    }

    updateEnabledStatusByWebvisArr(webvisArr,status){
        for (let i = 0; i < webvisArr.length; i++) {
            var aktNode = this.findNode('webvisnode', webvisArr[i]);
            this.updateEnabledStatus(aktNode,status)
        }
    }
    updateEnabledStatusForAllLeaves(status){
        var aktNodes = this.findAllLeaves(this.root);
        for (let i = 0; i < aktNodes.length; i++) {
            this.updateEnabledStatus(aktNodes[i],status)
        }
    }
    // Methode, die einen Knoten als Parameter nimmt und seinen enabled Status ermittelt, indem sie prüft, ob alle Blätter unter ihm enabled sind oder nicht  
    checkEnabledStatus(node) {
        // erstelle ein Array, das die Blätter speichert  
        let leaves = this.findAllLeaves(node);
        // überprüfe, ob das Array leer ist  
        if (leaves.length === 0) {
            // gib false zurück, weil es keine Blätter gibt  
            return false;
        }
        // überprüfe, ob alle Blätter den enabled Status true haben  
        for (let leaf of leaves) {
            // vergleiche den enabled Status mit true  
            if (leaf.enabled === true) {
                // gib false zurück, weil es ein Blatt gibt, das nicht true ist  
                return true;
            }
        }
        // gib true zurück, weil alle Blätter den enabled Status true haben  
        return false;
    }
    // Methode, die alle parent-Knoten untersucht  
    findParents(node) {
        // erstelle ein Array, das die parent-Knoten speichert  
        let parents = [];
        // überprüfe, ob der Knoten existiert  
        if (node !== null) {
            // gehe den Baum von unten nach oben durch  
            while (node.parent !== null) {
                // finde den parent-Knoten im Array  
                let parentNode = this.nodes.find(n => n.id === node.parent);
                // füge den parent-Knoten zum Array hinzu  
                if(node.nichtauswaehlbar === true && node.parent === parentNode.id){
                    //dann ist es ein zusammengesetztes Einzelteil, welches wir nicht als Baugruppe anerkennen,
                }
                else{
                    parents.push(parentNode);
                }
                // setze den aktuellen Knoten auf den parent-Knoten  
                node = parentNode;
            }
        }
        // gib das Array zurück  
        return parents;
    }

    getAlleBaugruppen(){
        var arr = [];

        for (let i = 0; i < this.nodes.length; i++) {
            if (this.nodes[i].children.length !== 0 &&
                this.nodes[i].children[0].nichtauswaehlbar === null) {
                arr.push(this.nodes[i])
            }            
        }

        return arr;
    }

    //fuer treeview
    getLetzteEbeneBaugruppen(){
        var arr = {"alle":[],"enabled":[],"disabled":[]};

        for (let i = 0; i < this.nodes.length; i++) {
            if (this.nodes[i].children.length !== 0 &&
                this.nodes[i].children[0].nichtauswaehlbar === null && 
                this.nodes[i].treeviewchildren.length === 0) {

                arr.alle.push(this.nodes[i].id)

                var baugrLeavesWithStatus = this.findLeavesWithStatus(this.nodes[i]);

                if(baugrLeavesWithStatus.enabledLeaves.length > 0 )
                    arr.enabled.push(this.nodes[i].id)
                if(baugrLeavesWithStatus.disabledLeaves.length > 0 )
                    arr.disabled.push(this.nodes[i].id)
            }            
        }

        return arr;
    }

    //gibt ein Objekt {"alleLeaves":[], "enabledLeaves":[], "disabledLeaves":[]} zurück
    findLeavesWithStatus(node){
        var arr = {};
        arr.allLeaves = [];
        arr.enabledLeaves = [];
        arr.disabledLeaves = [];

        let leaves = this.findAllLeaves(node);

        for (let i = 0; i < leaves.length; i++) {
            arr.allLeaves.push(leaves[i]);

            leaves[i].enabled === true ? 
                arr.enabledLeaves.push(leaves[i]) :
                arr.disabledLeaves.push(leaves[i]);
        }

        return arr;

    }

    findLeavesWithEnabledStatus(node, enabledOrDisabled) {
        // erstelle ein Array, das die Blätter speichert  
        let leaves = [];
        // verwende eine Warteschlange, um die Breitensuche durchzuführen  
        let queue = [node]; // eine Warteschlange, die mit dem Wurzel-Knoten beginnt  
        while (queue.length > 0) {
            // nimm den ersten Knoten aus der Warteschlange  
            let node = queue.shift();
            // überprüfe, ob der Knoten ein Blatt ist und nicht enabled ist  
            if (node.children.length === 0 && node.enabled === enabledOrDisabled) {
                // füge den Knoten zum Array hinzu  
                leaves.push(node);
            }
            // füge die child-Knoten zur Warteschlange hinzu  
            for (let child of node.children) {
                queue.push(child);
            }
        }
        // gib das Array zurück  
        return leaves;
    }

}

export { Node, Tree };

/* var arr = [
    { "id": "1", "parent": null, "name": "Vergaser_Luftfilter_komplett" },
    { "id": "2", "parent": "1", "name": "Vergaser_komplett" },
    { "id": "3", "parent": "2", "name": "Vergaser_Oberteil_zusammen" },
    { "id": "4", "parent": "2", "name": "Vergaser_Unterteil_zusammen" },
    { "id": "5", "parent": "3", "name": "Vergaser_Oberteil" },
    { "id": "6", "parent": "3", "name": "Vergaser_Oberteil_Kappe" },
    { "id": "7", "parent": "3", "name": "Schwimmernadelventil" },
    { "id": "8", "parent": "3", "name": "Schwimmer_zusammen" },
    { "id": "9", "parent": "8", "name": "Schwimmer_Teil1", "nichtauswaehlbar": true },
    { "id": "10", "parent": "8", "name": "Schwimmer_Teil2", "nichtauswaehlbar": true },
    { "id": "11", "parent": "8", "name": "Schwimmer_Teil3", "nichtauswaehlbar": true },
    { "id": "12", "parent": "8", "name": "Schwimmer_Teil4", "nichtauswaehlbar": true },
    { "id": "13", "parent": "4", "enabled": true, "name": "Vergaser_Unterteil" },
    { "id": "14", "parent": "4", "enabled": false, "name": "Vergaser_Unterteil_mitteldichtung" },
    { "id": "15", "parent": "1", "name": "Luftfilter_zusammen" },
    { "id": "16", "parent": "15", "enabled": false, "name": "Luftfilter_Filter" },
    { "id": "17", "parent": "15", "enabled": true, "name": "Luftfilter_Schraube" }
]
let nodesArr = [];
for (let i = 0; i < arr.length; i++) {
    nodesArr.push(new Node(arr[i]));
}
var meinTree = new Tree(nodesArr);
var node = meinTree.findNode('id', "16")
//console.log(meinTree.findAllLeaves(node)); 
//meinTree.checkEnabledStatus(node); 
meinTree.getAlleBlaetterFuerShowBaugruppe(node);
meinTree.getAlleBlaetterFuerHideBaugruppe(node); */

